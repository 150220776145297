<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">全景档案记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <radar-block title="结果详情">
      <div class="fx-m-between fx-wrap">
        <num-block class="with-margin" v-for="(item, index) in list1" :key="index" width="32%" :title="item.name"
          :num="item.value" :unit="item.unit" :num-color="item.color"></num-block>
      </div>
    </radar-block>
    <radar-block title="历史共债">
      <radar-block :title="`序号`+(index+1)" :key="index" v-for="(item, index) in list2">
        <div class="fx-m-between fx-wrap">
          <num-block class="with-margin" v-for="(child, position) in item" :key="position" width="32%" :title="child.name"
            :num="child.value" :unit="child.unit" :num-color="child.color"></num-block>
        </div>
      </radar-block>
    </radar-block>
  </div>
</template>

<script>
import numBlock from "./num-block";

import { mapState, mapActions, mapGetters } from "vuex";
import radarBlock from "./radar-block";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock, numBlock },
  methods: {
    ...mapActions("userReport/archives", ["getArchives"]),
    load (refresh = false) {
      this.getArchives(refresh).then(res => res && this.getReports());
    }
  },
  computed: {
    ...mapGetters("userReport/archives", ["list1", "list2"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/archives", ["date"])
  },
  created () {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
</style>
