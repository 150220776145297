<template>
  <div id="charts1" ref="charts1" ></div>
</template>

<script>
import * as echarts from 'echarts'
var color = ['#FC4567', '#2F8DF4', '#C25EC4']
export default {
  name: "bing",
  props: {
    value1: {
      type: Number,
      default: 0
    },
    value2: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    value1(val) {
      console.log(val)
      this.drawChart()
    },
    value2(val) {
      this.drawChart()
    }
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      this.chart = echarts.init(this.$refs.charts1)
      //https://www.makeapie.cn/echarts_content/xeDQNTsDja.html
      this.chart.setOption(
        {
          color: color,
          backgroundColor: '#fff',
          title: {
            left: 'center',
            top: '50%',
            textStyle: {
              fontSize: 16,
              color: '#000',
              fontWeight: 'normal'
            }
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            right: 20,
            top: 20
          },
          series: [{
            type: 'pie',
            roseType: 'radius',
            radius: ['30%', '60%'],
            data: [{
              value: this.value1,
              name: '消费分期类'
            }, {
              value: this.value2,
              name: '网络贷款类'
            },
            ],
            label: {
              normal: {
                formatter: '{font|{c}}\n{hr|}\n{font|{d}%}',
                rich: {
                  font: {
                    fontSize: 20,
                    padding: [5, 0],
                    color: '#000'
                  },
                  hr: {
                    height: 0,
                    borderWidth: 1,
                    width: '100%',
                    borderColor: '#000'
                  }
                }
              },
            },
            labelLine: {
              lineStyle: {
                color: '#000'
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            }
          }]
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
