<template>
  <div class="tc document-view">
    <document-generator/>
    <div class="jilu">
      <p class="title ft-bold">探针A</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>

    <div style="text-align: center;padding-top:10px;background: #fff">

<!--      result_code	否	string	探查结果编码-->
<!--      max_overdue_amt	否	string	最大逾期金额-->
<!--      max_overdue_days	否	string	最长逾期天数-->
<!--      latest_overdue_time	否	string	最近逾期时间-->
<!--      currently_overdue	否	string	当前逾期机构数-->
<!--      currently_performance	否	string	当前履约机构数-->
<!--      acc_exc	否	string	异常还款机构数-->
<!--      acc_sleep	否	string	睡眠机构数-->
        <div class="radar-current">
          <div class="radar-current-bottom fx-m-between">
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="探查结果" :num="getValue(result_code)" num-color="#f00"></num-block>
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="最大逾期金额" :num="max_overdue_amt" unit="元" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="最长逾期天数" :num="max_overdue_days" unit="天" num-color="red"></num-block>
          </div>
            <div class="radar-current-bottom fx-m-between">
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="最近逾期时间" :num="latest_overdue_time" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="当前逾期机构数" :num="currently_overdue" unit="个" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="当前履约机构数" :num="currently_performance" unit="个" num-color="red"></num-block>
            </div>
          <div class="radar-current-bottom fx-m-between">
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="异常还款机构数" :num="acc_exc" unit="个" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 3) - 1 + '%'"
                        title="睡眠机构数" :num="acc_sleep" unit="个" num-color="red"></num-block>

          </div>
        </div>

    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import radarBlock from "./radar-block";
import numBlock from "./num-block";
export default {
  mixins: [getReports],
  components: {
    DocumentGenerator,numBlock
  },



  computed: {
    ...mapState("userReport/tza", ["provider", "date",
      "currently_overdue",
      "max_overdue_amt",
      "acc_sleep",
      "currently_performance",
      "result_code",
      "max_overdue_days",
      "latest_overdue_time",
      "acc_exc"
    ]),
    ...mapGetters("userReport/tza", [

    ]),
    ...mapState("userReport", ["uid", "price"])
  },
  methods: {
    ...mapActions("userReport/tza", ["getTza"]),
    load(refresh = false) {
      this.getTza(refresh).then(res => res && this.getReports());
    },
    //   1：A(Overdue) 逾期未还款
// 2：B(Normal) 正常履约
// 3：B(Delay) 逾期又还款
// 4：U 无法确认
    getValue(val){
      if (val == "1") return "逾期未还款";
      return val == "2" ? "正常履约" : val == "3" ? "逾期又还款" : "无法确认";
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.tc {
  text-align: left;
  position: relative;
  .score {
    padding: 20px;
    &-card {
      width: 30%;
      background-color: #fff;
      box-shadow: 0px 0px 23px 0px rgba(82, 115, 227, 0.09);
      &-top {
        padding: 25px 40px;
        &-desc {
          margin-top: 17px;
          color: rgba(100, 100, 100, 1);
        }
        &-right {
          width: 68px;
          height: 68px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-bottom {
        padding: 18px 20px;
        color: #fff;
      }
    }
    &-bottom {
      margin-top: 40px;
    }
  }
  .response {
    padding: 20px;
    &-num-block {
      margin-bottom: 10px;
    }
  }
  .no-data {
    padding: 70px;
    text-align: center;
  }
  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    .title {
      font-size: 24px;
      color: #000000;
    }
    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
@media screen and (max-width: 480px) {
  .score .fx-m-between{
    display: block;
    .score-card{
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .response-num-block{
    display: block;
  }
}

                   .radar {
                     position: relative;

                     &-score {
                       width: 65%;
                       margin-left: 20px;

                       &-item {
                         width: 100%;
                         text-align: left;
                         margin-bottom: 20px;

                         &-name {
                           width: 75px;
                           margin-right: 20px;
                         }
                       }

                       &-tips {
                         width: 30%;
                         text-align: left;

                         h4 {
                           margin-bottom: 30px;
                         }
                       }
                     }

                     &-search {
                       padding: 0 20px;

                       .search-sum {
                         width: 30%;

                         &-top {
                           padding: 13px 20px;
                           margin-bottom: 2px;

                           .sum-num {
                             margin-left: 26px;
                             color: rgba(64, 158, 255, 1);
                           }
                         }

                         &-bottom {
                           padding: 13px 30px;

                           &-item {
                             .item-num {
                               color: rgba(64, 158, 255, 1);
                             }
                           }
                         }
                       }

                       &-num-block {
                         padding-top: 28px;
                       }
                     }

                     &-current {
                       padding-left: 20px;

                       &-bottom {
                         margin-bottom: 10px;

                         &-left {
                           width: 30%;
                           padding: 50px 20px;
                           box-sizing: border-box;
                           text-align: center;
                           margin-right: 5px;
                           vertical-align: middle;
                         }

                         .right-block {
                           width: 24%;
                           text-align: center;

                           &-top {
                             padding: 20px;
                             margin-bottom: 5px;
                           }

                           &-bottom {
                             padding: 10px;
                           }
                         }
                       }
                     }

                     &-behavior {
                       padding-left: 20px;
                     }

                     .jilu {
                       width: 100%;
                       height: 114px;
                       background: #ffffff;
                       padding: 20px 0;
                       box-sizing: border-box;
                       text-align: center;

                       .title {
                         font-size: 24px;
                         color: #000000;
                       }

                       .refreshBtn {
                         background-color: #f56c6c;
                         padding: 3px 5px;
                         color: #ffffff;
                         border: none;
                         border-radius: 3px;
                         cursor: pointer;
                         margin: 0 10px;
                         font-size: 14px;
                       }
                     }
                   }

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}
.radar-current{
  padding-bottom: 30px;
}
</style>
