<template>
  <div id="charts1" style="width:100%;height: 500px" ref="charts1" ></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: "bing",
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    value(val) {
      this.drawChart()
    }
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      this.chart = echarts.init(this.$refs.charts1)
      //https://www.makeapie.cn/echarts_content/xeDQNTsDja.html
      this.chart.setOption(
        {
          backgroundColor: "#fff",
          tooltip: {
            formatter: "{b}{c}"
          },
          series: [{
            tooltip: {
              show: false
            },
            "name": 'wrap',
            "type": 'pie',
            "hoverAnimation": false,
            "legendHoverLink": false,
            center: ['50%','60%'],
            "radius": ['0%', '7%'],
            "z": 5,
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },
              "emphasis": {
                "show": false
              }
            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": 100,
              itemStyle: {
                normal: {
                  color: "#072B79"
                },
                emphasis: {
                  color: "#072B79"
                }
              }
            }]
          }, {
            tooltip: {
              show: false
            },
            "name": 'wrap',
            "type": 'pie',
            "hoverAnimation": false,
            "legendHoverLink": false,
            center: ['50%','60%'],
            "radius": ['6%', '8%'],
            "z": 5,
            "label": {
              "normal": {
                "show": false,
                "position": 'center'
              },
              "emphasis": {
                "show": false
              }
            },
            "labelLine": {
              "normal": {
                "show": false
              }
            },
            "data": [{
              "value": 100,
              "show": false,
              itemStyle: {
                normal: {
                  color: "#24D8E7"
                },
                emphasis: {
                  color: "#24D8E7"
                }
              }
            }]
          }, {
            tooltip: {
              show: false
            },
            name: '刻度',
            type: 'gauge',
            radius: '83%',
            z: 1,
            min: 0,
            max: 1,
            center: ['50%','60%'],
            splitNumber: 5, //刻度数量
            startAngle: 180,
            endAngle: 0,
            axisLine: {
              show: true,
              lineStyle: {
                width: 5,
                color: [
                  [450/650, '#E71A6D'],
                  [530/650, '#F88168'],
                  [650/650, '#70C27E'],
                ]
              }
            }, //仪表盘轴线
            axisLabel: {
              show: false
            }, //刻度标签。
            axisTick: {
              show: true,
              lineStyle: {
                color: 'auto',
                width: 0
              },
              length: -15
            }, //刻度样式
            splitLine: {
              show: true,
              length: 0,
              lineStyle: {
                color: 'auto',
                width: 2
              }
            }, //分隔线样式
            detail: {
              show: false
            },
            pointer: {
              show: false
            }
          }, {
            name: '',
            type: 'gauge',
            radius: '80%',
            min: 0,
            max: 650,
            center: ['50%','60%'],
            data: [{
              value: this.value,
              name: '',
              show:false,
            }],
            splitNumber: 12, //刻度数量
            startAngle: 180,
            endAngle: 0,
            z: 5,
            axisLine: {
              show: true,
              lineStyle: {
                width: 0,
                color: [
                  [450, '#E71A6D'],
                  [530, '#F88168'],
                  [650, '#70C27E'],
                ]
              }
            }, //仪表盘轴线
            axisLabel: {
              show: true,
              color: '#000',
              fontSize:20,
              distance: -70,
              formatter:function(params){
                var value = params.toFixed(2)

                if(value == 0.00){
                  return '危'
                }else if(value == 500){
                  return '中'
                }else if(value == 650){
                  return '优'
                }else{
                  return ''
                }
              }
            }, //刻度标签。
            axisTick: {
              splitNumber: 10,
              show: true,
              lineStyle: {
                color: 'auto',
                width: 2
              },
              length: 20,
            }, //刻度样式
            splitLine: {
              show: true,
              length: 25,
              lineStyle: {
                color: 'auto',
                width: 5
              }
            }, //分隔线样式

            "itemStyle": {
              "normal": {
                "color": "#24D8E7" //指针颜色
              }
            },
            pointer: {
              width: 10,
              length: '80%'
            },
            detail: {
    //           【0，450】建议拒绝；
    // （450，530】建议审核；（530，650】，建议通过。
              formatter: function(params){
                if(params < 450){
                  return '建议拒绝'
                }
                if(params >= 450&& params < 530){
                  return '建议审核'
                }
                if(params >= 530 ){
                  return '建议通过'
                }
              },
              fontSize: 30,
              color: "#000",
              offsetCenter: ['0%', '-35%']
            },
            title: {
              offsetCenter: ['0', '-60%'],
              fontSize: 40,
              color: "#000",
              show: true
            },
          }]
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
