<template>
  <div class="radar document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">全景雷达记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
        刷新报告
      </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <radar-block title="报告评分">
      <div class="fx-m-between ft-sz-15 radarBox">
        <!-- 评分进度条 -->
        <div class="radar-score">
          <div class="radar-score-item fx-row" v-for="(item, index) in scoreList" :key="index">
            <span class="radar-score-item-name ft-medium c-dkGrey">{{
                item.name
              }}</span>
            <el-progress class="fx-1" :stroke-width="26" :percentage="item.value > 100 ? Math.floor(item.value / 10) : item.value
              " :color="item.color" :format="per => per + '分'" :text-inside="true"></el-progress>
          </div>
        </div>
        <div class="radar-score-tips">
          <h4 class="ft-bold">温馨提示：</h4>
          <p class="c-dkGrey">
            左侧4项评分分值区间均为0-100分，分值越高，用户可信度越高，参考意见60分为一个门槛，80分以上为优秀
          </p>
        </div>
      </div>
    </radar-block>
    <radar-block title="多头查询报告">
      <div class="radar-search fx-m-between ft-sz-15">
        <!-- 查询总次数 -->
        <div class="search-sum">
          <div class="search-sum-top fx-v-center c-bg-cyan">
            <span class="ft-medium ft-sz-14">查询总次数</span>
            <div class="sum-num">
              <span class="ft-sz-25">{{ applyDetail.A22160006 || 0 }}</span>
              <span class="ft-sz-14 c-dkGrey">&nbsp;次</span>
            </div>
          </div>
          <div class="search-sum-bottom fx-v-center fx-m-between c-bg-cyan">
            <div class="search-sum-bottom-item">
              <div class="c-grey ft-sz-12">近一个月</div>
              <div class="item-num ft-sz-18">
                <span>{{ applyDetail.A22160008 || 0 }}</span>
                <span class="ft-sz-12 c-dkGrey">&nbsp;次</span>
              </div>
            </div>
            <div class="search-sum-bottom-item">
              <div class="c-grey ft-sz-12">近三个月</div>
              <div class="item-num ft-sz-18">
                <span>{{ applyDetail.A22160009 || 0 }}</span>
                <span class="ft-sz-12 c-dkGrey">&nbsp;次</span>
              </div>
            </div>
            <div class="search-sum-bottom-item">
              <div class="c-grey ft-sz-12">近半年</div>
              <div class="item-num ft-sz-18">
                <span>{{ applyDetail.A22160010 || 0 }}</span>
                <span class="ft-sz-12 c-dkGrey">&nbsp;次</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 查询机构 -->
        <num-block class="radar-search-num-block" v-for="(item, index) in searchOrgs" :key="index" :title="item.name"
                   :num="item.num" :unit="item.unit" :width="'22%'"></num-block>
      </div>
    </radar-block>
    <radar-block title="信用现状报告">
      <div class="radar-current">
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in netList" :key="index" :width="Math.floor(100 / netList.length) - 1 + '%'"
                     :title="item.name" :num="item.num" :num-color="item.color"></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in financeList" :key="index"
                     :width="Math.floor(100 / financeList.length) - 1 + '%'" :title="item.name" :num="item.num"
                     :num-color="item.color"></num-block>
        </div>

        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in financeList4" :key="index"
                     :width="Math.floor(100 / financeList4.length) - 1 + '%'" :title="item.name" :num="item.num"
                     :num-color="item.color" :content="item.content || ''"></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in financeList5" :key="index"
                     :width="Math.floor(100 / financeList5.length) - 1 + '%'" :title="item.name" :num="item.num"
                     :num-color="item.color" :content="item.content || ''"></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in financeList3" :key="index"
                     :width="Math.floor(100 / financeList3.length) - 1 + '%'" :title="item.name" :num="item.num"
                     :num-color="item.color"></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between radar-cur">
          <div class="radar-current-bottom-left c-bg-cyan ft-sz-14 ft-medium">
            近12个月贷款金额范围内的贷款笔数
          </div>
          <div class="radar-current-bottom-right fx-m-between fx-1">
            <div class="right-block" v-for="(item, index) in loanList" :key="index">
              <div class="right-block-top c-bg-cyan ft-sz-14 ft-medium">
                {{ item.name }}
              </div>
              <div class="right-block-bottom c-bg-cyan ft-sz-25 c-black">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </radar-block>
    <radar-block title="行为雷达报告详情">
      <div class="radar-behavior">
        <el-table border :data="behavior" style="width: 100%" header-row-class-name="light-head"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="name" label="详情" align="center"></el-table-column>
          <el-table-column prop="oneMonth" label="近1个月" align="center"></el-table-column>
          <el-table-column prop="threeMonth" label="近3个月" align="center"></el-table-column>
          <el-table-column prop="sixMonth" label="近6个月" align="center"></el-table-column>
          <el-table-column prop="oneYear" label="近12个月" align="center"></el-table-column>
          <el-table-column prop="twoYear" label="近24个月" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>

    <radar-block title="信用详情">
      <div class="radar-current">
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in creditList.slice(0,3)"  :key="index" :width="Math.floor(100 / 3) - 1 + '%'"
                     :title="item.name" :num="item.num" ></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in creditList.slice(3,6)"  :key="index" :width="Math.floor(100 / 3) - 1 + '%'"
                     :title="item.name" :num="item.num" ></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in creditList.slice(6,9)"  :key="index" :width="Math.floor(100 / 3) - 1 + '%'"
                     :title="item.name" :num="item.num" ></num-block>
        </div>
        <div class="radar-current-bottom fx-m-between">
          <num-block v-for="(item, index) in creditList.slice(9)"  :key="index" :width="Math.floor(100 / 3) - 1 + '%'"
                     :title="item.name" :num="item.num" ></num-block>
        </div>
      </div>
    </radar-block>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import radarBlock from "./radar-block"
import numBlock from "./num-block"

export default {
  mixins: [getReports],
  components: { DocumentGenerator,radarBlock,numBlock },
  methods: {
    ...mapActions("userReport/explored", ["getExplored"]),
    load (refresh = false) {
      this.getExplored(refresh).then((res) => res && this.getReports());
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 4) {
        return "c-green ft-sz-13 ft-bold";
      } else if (rowIndex === 5) {
        return "c-red ft-sz-13 ft-bold";
      }
      return "ft-sz-13 ft-bold";
    },
  },
  computed: {
    ...mapState("userReport/explored", {
      provider: "provider",
      applyDetail: "apply_report_detail",
      date: "date"
    }),
    ...mapGetters("userReport/explored", [
      "scoreList",
      "creditList",
      "searchOrgs",
      "netList",
      "financeList",
      "financeList3",
      "financeList4",
      "financeList5",
      "loanList",
      "behavior"
    ]),
    ...mapState("userReport", ["uid", "price"])
  },
  // computed: {
  //   ...mapGetters("userReport/explored", ["apply", "behavior", "current",
  //     "scoreList",
  //     "searchOrgs",
  //     "netList",
  //     "financeList",
  //     "financeList3",
  //     "financeList4",
  //     "financeList5",
  //     "loanList",
  //     "behavior"]),
  //   ...mapState("userReport", ["uid", "price"]),
  //   ...mapState("userReport/explored", {
  //     provider: "provider",
  //     applyDetail: "apply_report_detail",
  //     date: "date"
  //   }),
  // },
  created () {
    this.load();
  },
};
</script>


<style lang="scss" scoped>
.radar {
  position: relative;

  &-score {
    width: 65%;
    margin-left: 20px;

    &-item {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;

      &-name {
        width: 75px;
        margin-right: 20px;
      }
    }

    &-tips {
      width: 30%;
      text-align: left;

      h4 {
        margin-bottom: 30px;
      }
    }
  }

  &-search {
    padding: 0 20px;

    .search-sum {
      width: 30%;

      &-top {
        padding: 13px 20px;
        margin-bottom: 2px;

        .sum-num {
          margin-left: 26px;
          color: rgba(64, 158, 255, 1);
        }
      }

      &-bottom {
        padding: 13px 30px;

        &-item {
          .item-num {
            color: rgba(64, 158, 255, 1);
          }
        }
      }
    }

    &-num-block {
      padding-top: 28px;
    }
  }

  &-current {
    padding-left: 20px;

    &-bottom {
      margin-bottom: 10px;

      &-left {
        width: 30%;
        padding: 50px 20px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .right-block {
        width: 24%;
        text-align: center;

        &-top {
          padding: 20px;
          margin-bottom: 5px;
        }

        &-bottom {
          padding: 10px;
        }
      }
    }
  }

  &-behavior {
    padding-left: 20px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}
</style>
