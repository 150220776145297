<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">信用综合报告</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <el-row>
      <el-col :span="8">
        <radar-block title="风险评估">
          <div class="radar-behavior">
            <div>分值在【0，650】之间，得分越低，风险越高</div>
            <bing style="width:100%;height: 400px" :value="score_norm_explain"></bing>
          </div>
        </radar-block>
      </el-col>
      <el-col :span="8">
        <radar-block title="分数评级">
          <cloud style="width:100%;height: 400px" :value="hit_risk_tagging"></cloud>
        </radar-block>
      </el-col>
      <el-col :span="8">
        <radar-block title="关联风险检测">
          <div class="radar-current">
            <div class="radar-current-bottom " >
              <num-block  :width="Math.floor(100 / 2) + '%'"
                          title="身份证关联手机号次数" :num="relevance_risk_check.m3_idcard_to_phone_time" num-color="red"></num-block>
              <num-block  :width="Math.floor(100 / 2)  + '%'" style="margin-top: 20px"
                          title="手机号关联身份证次数" :num="relevance_risk_check.m3_phone_to_idcard_time" num-color="red"></num-block>
            </div>
          </div>
        </radar-block>
      </el-col>
    </el-row>

<!--    d7_apply_time	int	近7天申请租赁次数-->
<!--    m1_apply_time	int	近1个月申请租赁次数-->
<!--    m3_apply_time	int	近3个月申请租赁次数-->
<!--    m6_apply_time	int	近6个月申请租赁次数-->
<!--    m12_apply_time	int	近12个月申请租赁次数-->
<!--    d7_apply_agency_time	int	近7天申请租赁机构数-->
<!--    m1_apply_agency_time	int	近1个月申请租赁机构数-->
<!--    m3_apply_agency_time	int	近3个月申请租赁机构数-->
<!--    m6_apply_agency_time	int	近6个月申请租赁机构数-->
<!--    m12_apply_agency_time	int	近12个月申请租赁机构数-->
    <radar-block title="历史租赁印记" >
      <div class="radar-current">
      <div class="radar-current-bottom fx-m-between">
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近7天申请租赁次数" :num="rent_history.d7_apply_time" ></num-block>
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近1个月申请租赁次数" :num="rent_history.m1_apply_time" ></num-block>
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近3个月申请租赁次数" :num="rent_history.m3_apply_time" ></num-block>
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近6个月申请租赁次数" :num="rent_history.m6_apply_time" ></num-block>

        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近12个月申请租赁次数" :num="rent_history.m12_apply_time" ></num-block>
      </div>
        <div class="radar-current-bottom fx-m-between" >
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近7天申请租赁机构数" :num="rent_history.d7_apply_agency_time" ></num-block>
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近1个月申请租赁机构数" :num="rent_history.m1_apply_agency_time" ></num-block>
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近3个月申请租赁机构数" :num="rent_history.m3_apply_agency_time" ></num-block>

        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近6个月申请租赁机构数" :num="rent_history.m6_apply_agency_time" ></num-block>
        <num-block  :width="Math.floor(100 / 5) - 1 + '%'"
                    title="近12个月申请租赁机构数" :num="rent_history.m12_apply_agency_time" ></num-block>
        </div>

      </div>
    </radar-block>
<!--    relevance_risk_check字段详情（关联风险检测）-->
<!--    字段	类型	含义-->
<!--    m3_idcard_to_phone_time	int	3个月身份证关联手机号次数-->
<!--    m3_phone_to_idcard_time	int	3个月手机号关联身份证次数-->


<!--    risk_list_check字段详情 （风险名单检测）-->
<!--    字段	类型	含义（0未命中；1命中）-->
<!--    census_register_high_risk_area	int	归属地位于高风险集中地区-->
<!--    idcard_hit_clzlwy	int	车辆租赁违约名单-->
<!--    idcard_hit_fztj	int	犯罪通缉名单-->
<!--    idcard_hit_gywzcc	int	故意违章乘车名单-->
<!--    phone_hit_txxh	int	通信小号库-->
<!--    phone_hit_xjhm	int	虚假号码库-->
<!--    result_xd	int	特殊关注名单-->
<!--    credit_rent	int	信用租赁风险名单-->
    <radar-block title="风险名单检测">
      <div class="radar-current">
        <div class="radar-current-bottom fx-m-between">
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="车辆租赁违约名单" :num="risk_list_check.census_register_high_risk_area==0?'未命中':'命中'" :num-color="risk_list_check.census_register_high_risk_area==1?'red':''"></num-block>
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="犯罪通缉名单" :num="risk_list_check.idcard_hit_fztj==0?'未命中':'命中'" :num-color="risk_list_check.idcard_hit_fztj==1?'red':''"></num-block>
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="故意违章乘车名单" :num="risk_list_check.idcard_hit_gywzcc==0?'未命中':'命中'" :num-color="risk_list_check.idcard_hit_gywzcc==1?'red':''"></num-block>
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="通信小号库" :num="risk_list_check.phone_hit_txxh==0?'未命中':'命中'" :num-color="risk_list_check.phone_hit_txxh==1?'red':''"></num-block>
        </div>
          <div class="radar-current-bottom fx-m-between">
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="虚假号码库" :num="risk_list_check.phone_hit_xjhm==0?'未命中':'命中'" :num-color="risk_list_check.phone_hit_xjhm==1?'red':''"></num-block>
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="特殊关注名单" :num="risk_list_check.result_xd==0?'未命中':'命中'" :num-color="risk_list_check.result_xd==1?'red':''"></num-block>
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="信用租赁风险名单" :num="risk_list_check.credit_rent==0?'未命中':'命中'" :num-color="risk_list_check.credit_rent==1?'red':''"></num-block>
          <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                      title="车辆租赁违约名单" :num="risk_list_check.census_register_high_risk_area==0?'未命中':'命中'" :num-color="risk_list_check.census_register_high_risk_area==1?'red':''"></num-block>
          </div>
      </div>
    </radar-block>
    <radar-block title="查询次数">
      <div class="radar-behavior">
        <el-table border :data="getChaXun" style="width: 100%" header-row-class-name="light-head" >
          <el-table-column prop="title" label="" align="center"></el-table-column>
          <el-table-column prop="n1" label="近7天" align="center"></el-table-column>
          <el-table-column prop="n2" label="近1个月" align="center"></el-table-column>
          <el-table-column prop="n3" label="近3个月" align="center"></el-table-column>

        </el-table>
      </div>
    </radar-block>
<!--    court_risk_info_list 基本信息字段详情（法院风险信息）-->
<!--    字段	类型	文字描述	备注-->
<!--    sort_time_string	str	审结日期	举例："2019年3月31日"-->
<!--    data_type	str	类型	举例：-->
<!--    "cpws": "裁判文书";-->
<!--    "ktgg": "开庭公告";-->
<!--    "ajlc": "案件流程信息";-->
<!--    "fygg": "法院公告";-->
<!--    "shixin": "失信公告";-->
<!--    "zxgg": "执行公告";-->
<!--    "bgt": "曝光台";-->
<!--    summary	str	摘要说明	举例："法院：上海市浦东新区人民法院 案号：（2017）沪0115民初1***9号"-->
<!--    compatibility	float	匹配度	举例：0.99-->
<!--    匹配度是一个概率（范围为0~1），匹配度为0，则完全不匹配；匹配度为1，则完全匹配。通常，匹配度达到0.8及以上，相似度已经很高，基本上可以确定是这个人；匹配度在0.5及以下，说明可以关联到部分信息，但是相似度较低，大概率不是这个人；匹配度在0.5-0.8时，需根据用户的风控策略，做取舍。-->
    <radar-block title="法院风险信息">
      <div class="radar-behavior">
        <el-table border :data="court_risk_info_list" style="width: 100%" header-row-class-name="light-head" >
          <el-table-column prop="sort_time_string" label="审结日期" align="center"></el-table-column>
          <el-table-column prop="data_type" label="类型" align="center">
            <template slot-scope="scope">
              {{court_risk_info_list_type[scope.row.data_type]}}
            </template>
          </el-table-column>
          <el-table-column prop="summary" label="摘要说明" align="center"></el-table-column>
          <el-table-column prop="compatibility" label="匹配度" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
<!--    apply_time1-->
<!--    apply_time3-->
<!--    apply_time6-->
<!--    apply_time12-->
    <radar-block title="近12个月申请情况">
      <el-row>
        <el-col :span="8">
          <bing2 style="width:100%;height:300px;margin-top: 50px" :value1="personal_loan_s.consumer_apply_mechanism_number" :value2="personal_loan_s.network_loan_apply_mechanis_mnumber"></bing2>

        </el-col>
        <el-col :span="16">
          <div>
            <div class="radar-current-top fx-m-between">
              <div class="radar-current-top-left flex">
                <div class="radar-current-top-left-title">申请机构总数: {{personal_loan_s.apply_mechanism_number|0}}家</div>
              </div>
              <div class="radar-current-top-right flex">
                <div class="radar-current-top-right-title">最后一次申请时间：{{personal_loan_s.last_apply_time|0}}</div>
              </div>
              <div class="radar-current-top-right flex">
                <div class="radar-current-top-right-title">距离最近一次申请已有：{{personal_loan_s.last_apply_time_distance|0}}天</div>
              </div>
            </div>
          </div>
          <bar2 style="width:100%;height:300px" :value1="[personal_loan_s.apply_time12|0,personal_loan_s.apply_time6|0,personal_loan_s.apply_time3|0,personal_loan_s.apply_time1|0]"></bar2>

        </el-col>
      </el-row>
    </radar-block>
    <radar-block title="近12个月放款情况">
      <el-row>
        <el-col :span="8">
          <bing2 style="width:100%;height:300px;margin-top: 50px" :value1="personal_loan_f.cflenders" :value2="personal_loan_f.nllenders"></bing2>

        </el-col>
        <el-col :span="16">
          <div>
            <div class="radar-current-top fx-m-between">
              <div class="radar-current-top-left flex">
                <div class="radar-current-top-left-title">申请机构总数: {{personal_loan_f.lenders|0}}家</div>
              </div>
              <div class="radar-current-top-right flex">
                <div class="radar-current-top-right-title">最后一次申请时间：{{personal_loan_f.lend_time|0}}</div>
              </div>
              <div class="radar-current-top-right flex">
                <div class="radar-current-top-right-title">距离最近一次申请已有：{{personal_loan_f.lend_time_distance|0}}天</div>
              </div>
            </div>
          </div>
          <bar2 style="width:100%;height:300px" :value1="[personal_loan_f.lend_number12|0,personal_loan_f.lend_number6|0,personal_loan_f.lend_number3|0,personal_loan_f.lend_number1|0]"></bar2>

        </el-col>
      </el-row>
    </radar-block>
<!--    personal_loan_h字段详情 (贷款还款信息 )-->
<!--    字段	类型	含义-->
<!--    repay_fail1	int	近1个月还款异常次数-->
<!--    repay_fail3	int	近3个月还款异常次数-->
<!--    repay_fail6	int	近6个月还款异常次数-->
<!--    repay_fail12	int	近12个月还款异常次数-->
<!--    repay_fail24	int	近24个月还款异常次数-->
<!--    repay_succ1	int	近1个月履约次数-->
<!--    repay_succ3	int	近3个月履约次数-->
<!--    repay_succ6	int	近6个月履约次数-->
<!--    repay_succ12	int	近12个月履约次数-->
<!--    repay_succ24	int	近24个月履约次数-->
<!--    repay_money1	str	近1个月履约金额-->
<!--    repay_money3	str	近3个月履约金额-->
<!--    repay_money6	str	近6个月履约金额-->
<!--    repay_money12	str	近12个月履约金额-->
<!--    repay_money24	str	近24个月履约金额-->
    <radar-block title="履约情况">
      <div class="radar-behavior">
        <el-table border :data="getLvYue" style="width: 100%" header-row-class-name="light-head" >
          <el-table-column prop="title" label="" align="center"></el-table-column>
          <el-table-column prop="n1" label="还款异常次数" align="center"></el-table-column>
          <el-table-column prop="n2" label="履约次数" align="center"></el-table-column>
          <el-table-column prop="m1" label="履约金额" align="center"></el-table-column>

        </el-table>
      </div>
    </radar-block>
<!--    personal_overdue_history字段详情(历史逾期记录)-->
<!--    字段	类型	含义	说明-->
<!--    datalist	Array	逾期详情	字段失效，无数据意义-->
<!--    counts	int	逾期总次数	/-->
<!--    overdue_mechanism_number	int	逾期机构总数	/-->
<!--    overdue_money	str	逾期总金额（元）	数据会返回为“1，2，3“等对照值，对照区间请查看3.2.2.1金额区间说明-->
    <radar-block title="逾期记录">
      <div class="radar-behavior">
        <el-table border :data="[personal_overdue_history]" style="width: 100%" header-row-class-name="light-head" >
          <el-table-column prop="counts" label="逾期总次数" align="center"></el-table-column>
          <el-table-column prop="overdue_mechanism_number" label="逾期机构总数" align="center"></el-table-column>
          <el-table-column prop="overdue_money" label="逾期总金额（元）" align="center">
            <template slot-scope="scope">
              {{overdue_money(scope.row.overdue_money)}}
            </template>
          </el-table-column>


        </el-table>
      </div>
    </radar-block>
<!--    <radar-block title="逾期记录详情">-->
<!--      <div class="radar-behavior">-->
<!--        <el-table border :data="[]" style="width: 100%" header-row-class-name="light-head" >-->
<!--          <el-table-column prop="n1" label="" align="center"></el-table-column>-->
<!--          <el-table-column prop="n2" label="" align="center"></el-table-column>-->
<!--          <el-table-column prop="m1" label="近7天" align="center"></el-table-column>-->
<!--          <el-table-column prop="m2" label="近1个月" align="center"></el-table-column>-->
<!--          <el-table-column prop="m3" label="近3个月" align="center"></el-table-column>-->
<!--          <el-table-column prop="m4" label="近6个月" align="center"></el-table-column>-->

<!--        </el-table>-->
<!--      </div>-->
<!--    </radar-block>-->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import Bing from "@/components/echarts/bing"
import Bing2 from "@/components/echarts/bing2"
import Bar2 from "@/components/echarts/bar2"
import cloud from "@/components/echarts/cloud"
import radarBlock from "./radar-block";
import numBlock from "./num-block";

export default {
  mixins: [getReports],
  components: { DocumentGenerator,Bing,radarBlock,numBlock,Bing2,Bar2,cloud},
  methods: {
    ...mapActions("userReport/lightning", ["getLightning"]),
    load(refresh = false) {
      this.getLightning(refresh).then((res) => res && this.getReports());
    },
    overdue_money(type){
      if (type == 1) return "（0-500）"
      if (type == 2) return "【500-1000）"
      if (type == 3) return "【1000-2000）"
      if (type == 4) return "【2000-3000）"
      if (type == 5) return "【3000-5000）"
      if (type == 6) return "【5000-10000）"
      if (type == 7) return "【10000-20000）"
      if (type == 8) return "【20000-30000）"
      if (type == 9) return "【3000-5000）"
      if (type == 10) return "【5000-10000）"
      if (type == 11) return "【10000-20000）"
      if (type == 12) return "【20000-30000）"
      if (type == 13) return "【30000-50000）"
      if (type == 14) return "【50000-100000）"
      if (type == 15) return "【100000-∞）"
      return ""
    },

  },
  computed: {
    court_risk_info_list_type(){
      return {
        "ktgg": "开庭公告",
        "cpws": "裁判文书",
        "ajlc": "案件流程信息",
        "fygg": "法院公告",
        "shixin": "失信公告",
        "zxgg": "执行公告",
        "bgt": "曝光台"
      }
    },
    getLvYue(){
      return [
        {title:'近1月',n1:this.personal_loan_h.repay_fail1,n2:this.personal_loan_h.repay_succ1,m1:this.personal_loan_h.repay_money1},
        {title:'近3月',n1:this.personal_loan_h.repay_fail3,n2:this.personal_loan_h.repay_succ3,m1:this.personal_loan_h.repay_money3},
        {title:'近6月',n1:this.personal_loan_h.repay_fail6,n2:this.personal_loan_h.repay_succ6,m1:this.personal_loan_h.repay_money6},
        {title:'近12月',n1:this.personal_loan_h.repay_fail12,n2:this.personal_loan_h.repay_succ12,m1:this.personal_loan_h.repay_money12},
        {title:'近24月',n1:this.personal_loan_h.repay_fail24,n2:this.personal_loan_h.repay_succ24,m1:this.personal_loan_h.repay_money24}
      ]
    },

    getChaXun(){
      return [
        {title:"机构查询总数",n1:this.personal_loan_demand.d7_apply_setup_time,n2:this.personal_loan_demand.m1_apply_setup_time,n3:this.personal_loan_demand.m3_apply_setup_time},
        {title:"信用卡",n1:this.personal_loan_demand.d7_apply_setup_time_xyk,n2:this.personal_loan_demand.m1_apply_setup_time_xyk,n3:this.personal_loan_demand.m3_apply_setup_time_xyk},
        {title:"P2P平台",n1:this.personal_loan_demand.d7_apply_setup_time_p2pwd,n2:this.personal_loan_demand.m1_apply_setup_time_p2pwd,n3:this.personal_loan_demand.m3_apply_setup_time_p2pwd},
        {title:"其他类型公司",n1:this.personal_loan_demand.d7_apply_setup_time_other,n2:this.personal_loan_demand.m1_apply_setup_time_other,n3:this.personal_loan_demand.m3_apply_setup_time_other},
        {title:"一般消费分期平台",n1:this.personal_loan_demand.d7_apply_setup_time_ybxffq,n2:this.personal_loan_demand.m1_apply_setup_time_ybxffq,n3:this.personal_loan_demand.m3_apply_setup_time_ybxffq},
        {title:"大型消费金融公司",n1:this.personal_loan_demand.d7_apply_setup_time_dxxfjr,n2:this.personal_loan_demand.m1_apply_setup_time_dxxfjr,n3:this.personal_loan_demand.m3_apply_setup_time_dxxfjr},
        {title:"小额贷款公司",n1:this.personal_loan_demand.d7_apply_setup_time_xedkgs,n2:this.personal_loan_demand.m1_apply_setup_time_xedkgs,n3:this.personal_loan_demand.m3_apply_setup_time_xedkgs}
      ]
    },

    ...mapGetters("userReport/lightning", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/lightning", ["date","base_info","score_norm_explain","rent_history","relevance_risk_check","risk_list_check","court_risk_info_list",
    "personal_loan_h","personal_overdue_history","personal_loan_demand","personal_loan_f","personal_loan_s","hit_risk_tagging",
    ]),
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-image {
  position: relative;
  top: -180px;
  z-index: 0;
}
.el-col-6 {
  width: 20% !important;
}
.el-col {
  border-radius: 0px;
}
.bg-purple-dark {
  background: #F3F8FE;
  border-bottom: 1px solid #e8eff6;
}
.bg-purple {
  background: #f7f3f0;
  border-bottom: 1px solid #ede8e4;
}
.bg-purple-light {
  background: #d3dce6;
  border-bottom: 1px solid #c6cfd9;
}
.bg-purple-light2 {
  background: #dbe6f3;
  border-bottom: 1px solid #d3dce6;
}
.bg-jihei {
  background: #fa4947;
}
.bg-gaowei {
  background: #ce760f;
}
.bg-guanzhu {
  background: #227cd7;
}
.bg-huidu {
  background: #22c3bc;
}
.bg-weimz {
  background: #489f12;
}
.grid-content {
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  color: #000;
  font-size: 14px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 999;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }

}
.radar-current-bottom{
  margin-bottom: 5px;
}
</style>
