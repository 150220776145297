<template>
  <div class="probe document-view" style="height: 100%">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">流水报告查询</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <iframe v-if="reportURL" style="border: none" width="100%" height="4200px" :src="reportURL"></iframe>
    <el-row :gutter="12" v-else>
      <div style="font-size: 30px;margin-bottom: 10px">引导流程说明</div>
      <div class="text-row">
        <div>
          <div>
            1.复制引导链接或二维码图片，并发送给客户
            <ul style="margin-left: 50px;margin-top: 10px">
              <li>1、引导链接：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-tooltip class="item" effect="dark" content="点击复制引导链接" placement="top-end">
                  <span style="color: #00be6e;cursor: pointer" @click="copy">点击复制引导链接</span>
                </el-tooltip>
              </li>
              <li style="display: flex;margin-top: 10px">2、引导二维码：&nbsp;
                <el-tooltip class="item" effect="dark" content="点击看大图" placement="top-end">
                    <el-image
                      style="width: 100px; height: 100px"
                      src="https://xyfile1.oss-cn-heyuan.aliyuncs.com/img/code.png"
                      :preview-src-list="['https://xyfile1.oss-cn-heyuan.aliyuncs.com/img/code.png']">
                    </el-image>
                </el-tooltip>
              </li>
              <li style="display: flex;margin-top: 10px">3、用户教程：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <video width="400"  controls="controls" src="https://xyfile1.oss-cn-heyuan.aliyuncs.com/img/help.mp4"/>
              </li>
            </ul>
          </div>
          <p>
            2.客户根据引导链接的提示，将微信账单或者支付宝账单发送到引导链接中提示的邮箱（时间范围需选择一整年），并等待审核
          </p>
          <p>
            3.用户账单发送完毕后，等待后台解析数据（预计一分钟），然后查询流水报告或直接点击刷新按钮
          </p>

        </div>
      </div>
      <div style="font-size: 20px;font-weight: bold">查不到数据不扣费！！！</div>
    </el-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";

export default {
  mixins: [getReports],
  components: {
    DocumentGenerator
  },
  methods: {
    ...mapActions("userReport/liushui", ["getLiuShui"]),
    load (refresh = false) {
      this.getLiuShui(refresh).then((res) =>
        {
          res && this.getReports()
          if(res.data == null){
            this.$confirm('未查询到人员相关报告，请稍后再试！本次查询不扣费！！', '温馨提示', {
              confirmButtonText: '我知道了',
              type: 'warning',
              showCancelButton: false
            }).then(() => {

            }).catch(() => {

            });
          }

        }

      )
    },
    copy(){
      navigator.clipboard.writeText("https://flow-process.eeiai.cn/billProcess?accountId=3b4aa9158bcb4a72a82e87442429b328")
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    }
  },
  computed: {
    ...mapGetters("userReport/liushui", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/liushui", ["date","reportURL"])
  },
  created () {
    this.load();
  },
};
</script>

<style lang="scss" scoped>

.text-row{
  // margin: 10px 50px 10px 10px;
}
.el-card__body{
  padding: 5px!important;
}
.name{
  font-weight: bold;
  color: #000;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  width: max-content;
}
.cbody{
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tag-red{
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.tag-green{
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}
</style>
