<template>
  <div class="chart fx-v-center">
    <div ref="mychart" class="chart-wrap"></div>
    <div class="chart-desc ft-bold ft-sz-14">
      <div class="chart-desc-title c-black ft-sz-15">{{name}}</div>
      <div v-html="chartData.tips"></div>
    </div>
  </div>
</template>

<script>
import echarts from '@/lib/echarts';

export default {
  props: {
    name: {
      type: String,
      deafult: ''
    },
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    draw() {
      let myChart = echarts.init(this.$refs.mychart);
      let option = {
        series: [
          {
            type: 'gauge',
            radius: '100%',
            min: this.chartData.min || 0,
            max: this.chartData.max,
            axisLine: {
              lineStyle: {
                color: [
                  [0.25, '#54e2ac'],
                  [0.75, '#5273e3'],
                  [1, '#ff2750']
                ],
                width: 10
              }
            },
            splitNumber: 4,
            splitLine: { show: false },
            axisTick: { length: 1 }, // 刻度
            axisLabel: { show: false }, // 不显示刻度标签
            pointer: { length: '70%', width: 2 },
            detail: { fontSize: 20, fontWeight: 800, offsetCenter: [0, '60%'], formatter: this.chartData.format },
            data: [{ value: this.chartData.value }]
          }
        ]
      };
      myChart.setOption(option, true);
    }
  },
  mounted() {
    this.draw();
  },
  watch: {
    chartData() {
      this.draw();
    }
  }
};
</script>

<style lang="scss" scoped>
.chart {
  width: calc(50% - 3px);
  background-color: #fff;
  padding: 24px 50px;
  &-wrap {
    width: 30%;
    height: 30%;
    min-width: 160px;
    min-height: 150px;
    margin-right: 30px;
  }
  &-desc {
    padding-left: 30px;
    // white-space: nowrap;
    &-title {
      margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 480px) {
  .chart{
    width: 100%;
    display: block;
    &-wrap{
      display: block;
    }
     &-desc {
       display: block;
       width: 100%;
       padding-left: 0;
     }
  }
}
</style>
