<template>
  <div class="radar document-view">

    <div class="jilu">
      <p class="title ft-bold">多头借贷</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
        刷新报告
      </button>
        <span>￥{{ price }}/次</span>
      </p>
      <div style="color: red;background-color: white;font-size: 14px">注：二要素进件的客户请忽略手机号对应的数据</div>

    </div>
    <div style="text-align: center;padding-top:10px;background: #fff">
      <radar-block title="基本信息">
        <div class="radar-current">
          <div class="radar-current-bottom fx-m-between">
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                       title="是否存在逾期未结清" :num="ovdure_flag==1?'当前仍在逾期':'已结清'" :num-color="ovdure_flag==1?'red':'rgb(64, 158, 255)'"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="当前逾期机构树" :num="ovdure_org_num" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="当前逾期金额" :num="ovdure_org_money" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="首次负债距今时长" :num="debt_first_distance_now" ></num-block>
          </div>
          <div class="radar-current-bottom fx-m-between">
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="负债机构总数" :num="debt_org_total_num" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="消费金融类机构数" :num="debt_shopping_total_num" ></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="网络贷款类机构数" :num="debt_www_total_num" ></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="已结清负债机构数" :num="debt_settled_num" ></num-block>
          </div>
          <div class="radar-current-bottom fx-m-between">
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="负债机构数近7天" :num="debt_org_d7_num" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="负债机构数近1月" :num="debt_org_m1_num" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="负债机构数近3月" :num="debt_org_m3_num" num-color="red"></num-block>
            <num-block  :width="Math.floor(100 / 4) - 1 + '%'"
                        title="负债机构数近6月" :num="debt_org_m6_num" num-color="red"></num-block>
          </div>


        </div>
      </radar-block>

      <radar-block title="还款详情">
        <div class="radar-behavior">
          <el-table border :data="buildData" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName2">
            <el-table-column prop="name" label="" align="center"></el-table-column>
            <el-table-column prop="m1" label="近7天" align="center"></el-table-column>
            <el-table-column prop="m2" label="近1个月" align="center"></el-table-column>
            <el-table-column prop="m3" label="近3个月" align="center"></el-table-column>
            <el-table-column prop="m4" label="近6个月" align="center"></el-table-column>

          </el-table>
        </div>
      </radar-block>

      <radar-block title="查询详情">
        <div class="radar-behavior">
          <el-table border :data="buildUUData" style="width: 100%" header-row-class-name="light-head" :span-method="objectSpanMethod"
                    :row-class-name="tableRowClassName">
            <el-table-column prop="n1" label="" align="center"></el-table-column>
            <el-table-column prop="n2" label="" align="center"></el-table-column>
            <el-table-column prop="m1" label="近7天" align="center"></el-table-column>
            <el-table-column prop="m2" label="近1个月" align="center"></el-table-column>
            <el-table-column prop="m3" label="近3个月" align="center"></el-table-column>
            <el-table-column prop="m4" label="近6个月" align="center"></el-table-column>

          </el-table>
        </div>
      </radar-block>
    </div>
      <div class="operator-bottom">
        <p>*注：本报告仅提供您了解自己的信用状况，请妥善保管，因保管不当造成个人隐私泄露的，本公司将不承担相关责任。</p>
      </div>

  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex';
import getReports from '@/mixins/getReports';
import radarBlock from "./radar-block";
import numBlock from "./num-block";

// import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [getReports],
  components: {
    radarBlock,
    numBlock,
  },
  data() {
    return {
      showApprove:false,
      tmwidth:'25%',
      saveData: "",
      loading:false,
      loadingMessage: '数据处理中...',
      smsCount: 0
    };
  },
  computed: {

    ...mapState('userReport/head', [
      'apply_org_by_phone_m3_num',
      'repay_succ_m3_money',
      'apply_by_id_m1_num',
      'debt_org_m3_num',
      'repay_fail_m3_num',
      'repay_fail_m1_money',
      'repay_succ_d7_money',
      'repay_succ_m1_money',
      'repay_succ_m6_money',
      'debt_www_total_num',
      'apply_by_phone_m1_num',
      'apply_by_id_d7_num',
      'apply_org_by_id_m6_num',
      'apply_by_phone_d7_num',
      'apply_org_by_id_m1_num',
      'apply_org_by_phone_m6_num',
      'debt_org_total_num',
      'repay_fail_d7_money',
      'apply_by_id_m3_num',
      'repay_succ_m6_num',
      'debt_shopping_total_num',
      'repay_fail_m1_num',
      'repay_succ_m1_num',
      'ovdure_org_num',
      'apply_org_by_phone_m1_num',
      'debt_org_m1_num',
      'debt_org_m6_num',
      'debt_first_distance_now',
      'repay_succ_lately_num',
      'repay_fail_m6_money',
      'apply_by_phone_m6_num',
      'repay_fail_m3_money',
      'repay_fail_m6_num',
      'debt_org_d7_num',
      'apply_org_by_phone_d7_num',
      'debt_settled_num',
      'ovdure_org_money',
      'ovdure_flag',
      'repay_fail_d7_num',
      'apply_org_by_phone_m3_num',
      'apply_org_by_id_m3_num',
      'repay_succ_m3_num',
      'apply_by_id_m6_num',
      'apply_by_phone_m3_num',
      'repay_succ_d7_num',
      'repay_fail_m3_money',
      'repay_fail_m6_money',
      'apply_by_phone_m6_num',
      'apply_org_by_id_d7_num',
      'buildData',
      'buildUUData'
    ]),

    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/head", {
      provider: "provider",
      applyDetail: "apply_report_detail",
      date: "date"
    }),


  },
  watch: {
    ovdure_org_money: {
      handler(val) {
      }
    }
  },
  methods: {
    ...mapActions('userReport/head', ['getHead']),
    // 秒数转化时长
    load(refresh = false) {
      this.getHead(refresh).then(res => res && this.getReports());
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    tableRowClassName2({ row, rowIndex }) {
      if (rowIndex === 1 || rowIndex === 0) {
        return "c-red ft-sz-13 ft-bold";
      } else{
        return "c-green ft-sz-13 ft-bold";
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 4) {
        return "c-green ft-sz-13 ft-bold";
      } else if (rowIndex === 5) {
        return "c-red ft-sz-13 ft-bold";
      }
      return "ft-sz-13 ft-bold";
    },
  },
  mounted() {
  },
  created() {
    this.load();
    // if(document.documentElement.clientWidth < 480){
    //   this.tmwidth = '90%'
    // };
  }
};
</script>

<style lang="scss" scoped>
.radar {
  position: relative;

  &-score {
    width: 65%;
    margin-left: 20px;

    &-item {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;

      &-name {
        width: 75px;
        margin-right: 20px;
      }
    }

    &-tips {
      width: 30%;
      text-align: left;

      h4 {
        margin-bottom: 30px;
      }
    }
  }

  &-search {
    padding: 0 20px;

    .search-sum {
      width: 30%;

      &-top {
        padding: 13px 20px;
        margin-bottom: 2px;

        .sum-num {
          margin-left: 26px;
          color: rgba(64, 158, 255, 1);
        }
      }

      &-bottom {
        padding: 13px 30px;

        &-item {
          .item-num {
            color: rgba(64, 158, 255, 1);
          }
        }
      }
    }

    &-num-block {
      padding-top: 28px;
    }
  }

  &-current {
    padding-left: 20px;

    &-bottom {
      margin-bottom: 10px;

      &-left {
        width: 30%;
        padding: 50px 20px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .right-block {
        width: 24%;
        text-align: center;

        &-top {
          padding: 20px;
          margin-bottom: 5px;
        }

        &-bottom {
          padding: 10px;
        }
      }
    }
  }

  &-behavior {
    padding-left: 20px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}
</style>
