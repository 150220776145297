<template>
  <div class="tc document-view">
    <document-generator/>
    <div class="jilu">
      <p class="title ft-bold">法院综合</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>

    <div style="text-align: center;padding-top:10px;background: #fff">

      <radar-block title="还款详情">
        <div class="radar-behavior">
          <el-table border :data="formData1" style="width: 100%" header-row-class-name="light-head"
                    :row-class-name="tableRowClassName">
            <el-table-column prop="name" label="" align="center"></el-table-column>
            <el-table-column prop="n1" label="近1天" align="center"></el-table-column>
            <el-table-column prop="n7" label="近7天" align="center"></el-table-column>
            <el-table-column prop="n14" label="近14天" align="center"></el-table-column>
            <el-table-column prop="n21" label="近21天" align="center"></el-table-column>
            <el-table-column prop="n30" label="近30天" align="center"></el-table-column>
            <el-table-column prop="n90" label="近90天" align="center"></el-table-column>
            <el-table-column prop="n180" label="近180天" align="center"></el-table-column>


          </el-table>
        </div>
      </radar-block>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import radarBlock from "./radar-block";
import numBlock from "./num-block";
export default {
  mixins: [getReports],
  components: {
    DocumentGenerator,    radarBlock,
  },
  computed: {
    ...mapState("userReport/faYuan", ["provider", "date"]),
    ...mapGetters("userReport/faYuan", [ "formData1" ,"xyp_t01ccbzzz"

    ]),
    ...mapState("userReport", ["uid", "price"])
  },
  methods: {
    ...mapActions("userReport/faYuan", ["getFaYuan"]),
    load(refresh = false) {
      this.getFaYuan(refresh).then(res => res && this.getReports());
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.tc {
  text-align: left;
  position: relative;
  .score {
    padding: 20px;
    &-card {
      width: 30%;
      background-color: #fff;
      box-shadow: 0px 0px 23px 0px rgba(82, 115, 227, 0.09);
      &-top {
        padding: 25px 40px;
        &-desc {
          margin-top: 17px;
          color: rgba(100, 100, 100, 1);
        }
        &-right {
          width: 68px;
          height: 68px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-bottom {
        padding: 18px 20px;
        color: #fff;
      }
    }
    &-bottom {
      margin-top: 40px;
    }
  }
  .response {
    padding: 20px;
    &-num-block {
      margin-bottom: 10px;
    }
  }
  .no-data {
    padding: 70px;
    text-align: center;
  }
  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    .title {
      font-size: 24px;
      color: #000000;
    }
    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
@media screen and (max-width: 480px) {
  .score .fx-m-between{
    display: block;
    .score-card{
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .response-num-block{
    display: block;
  }
}

                   .radar {
                     position: relative;

                     &-score {
                       width: 65%;
                       margin-left: 20px;

                       &-item {
                         width: 100%;
                         text-align: left;
                         margin-bottom: 20px;

                         &-name {
                           width: 75px;
                           margin-right: 20px;
                         }
                       }

                       &-tips {
                         width: 30%;
                         text-align: left;

                         h4 {
                           margin-bottom: 30px;
                         }
                       }
                     }

                     &-search {
                       padding: 0 20px;

                       .search-sum {
                         width: 30%;

                         &-top {
                           padding: 13px 20px;
                           margin-bottom: 2px;

                           .sum-num {
                             margin-left: 26px;
                             color: rgba(64, 158, 255, 1);
                           }
                         }

                         &-bottom {
                           padding: 13px 30px;

                           &-item {
                             .item-num {
                               color: rgba(64, 158, 255, 1);
                             }
                           }
                         }
                       }

                       &-num-block {
                         padding-top: 28px;
                       }
                     }

                     &-current {
                       padding-left: 20px;

                       &-bottom {
                         margin-bottom: 10px;

                         &-left {
                           width: 30%;
                           padding: 50px 20px;
                           box-sizing: border-box;
                           text-align: center;
                           margin-right: 5px;
                           vertical-align: middle;
                         }

                         .right-block {
                           width: 24%;
                           text-align: center;

                           &-top {
                             padding: 20px;
                             margin-bottom: 5px;
                           }

                           &-bottom {
                             padding: 10px;
                           }
                         }
                       }
                     }

                     &-behavior {
                       padding-left: 20px;
                     }

                     .jilu {
                       width: 100%;
                       height: 114px;
                       background: #ffffff;
                       padding: 20px 0;
                       box-sizing: border-box;
                       text-align: center;

                       .title {
                         font-size: 24px;
                         color: #000000;
                       }

                       .refreshBtn {
                         background-color: #f56c6c;
                         padding: 3px 5px;
                         color: #ffffff;
                         border: none;
                         border-radius: 3px;
                         cursor: pointer;
                         margin: 0 10px;
                         font-size: 14px;
                       }
                     }
                   }

@media screen and (max-width: 480px) {
  .radar {
    .radarBox {
      display: block;

      .radar-score {
        width: 100%;
        margin-left: 0;
      }

      .radar-score-tips {
        width: 100%;
      }
    }

    &-search {
      display: block;

      .search-sum {
        width: 100%;
      }

      .num-block {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
    }

    &-cur {
      display: block;

      .ft-medium {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

  }

  .radar-current-bottom {
    display: block;
  }
}

</style>
