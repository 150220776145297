<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">JT详情版</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
<!--    {"诚信签约":"{}","信证签":"{}","叮咚合约":"{}","信条签":{"msg":"是平台用户","zongji":"5000.0","dangqian":"0.0正常还款"},"信而签":0,"云存证":"{}","橙子见证":"{}","优捷签":0,"桔子存证":"{}","合畅签":0,"无忧":0,"橙信签":"{}","安心借条":0,"履行证":"{}","伙伴合约":0,"一纸签":0,"存证优管家":"{}","宜信电子签":"{}","极简合约":0,"速信签":0,"橙信见证":"{}","合约说":0}-->
    <el-row :gutter="12" v-if="result">
      <el-col :span="6" v-for="(it,index) in Object.keys(JSON.parse(result))"  :key="index">
        <div class="cbody" shadow="always"  >
          <!--    {"诚信签约":"{}","信证签":"{}","叮咚合约":"{}","信条签":{"msg":"是平台用户","zongji":"5000.0","dangqian":"0.0正常还款"},
          "信而签":0,"云存证":"{}","橙子见证":"{}","优捷签":0,"桔子存证":"{}","合畅签":0,"无忧":0,"橙信签":"{}","安心借条":0,
          "履行证":"{}","伙伴合约":0,"一纸签":0,"存证优管家":"{}","宜信电子签":"{}","极简合约":0,"速信签":0,"橙信见证":"{}","合约说":0}-->
          <img src="../../../assets/icon/chengxin.jpg" v-if="it == '诚信签约'">
          <img src="../../../assets/icon/xinzheng.png" v-else-if="it == '信证签'">
          <img src="../../../assets/icon/dingdong.png" v-else-if="it == '叮咚合约'">
          <img src="../../../assets/icon/xintiao.jpg" v-else-if="it == '信条签'">
          <img src="../../../assets/icon/xiner.jpg" v-else-if="it == '信而签'">
          <img src="../../../assets/icon/yuncun.png" v-else-if="it == '云存证'">
          <img src="../../../assets/icon/juzi.jpg" v-else-if="it == '橙子见证'">
          <img src="../../../assets/icon/youjie.png" v-else-if="it == '优捷签'">
          <img src="../../../assets/icon/chengxinqian.png" v-else-if="it == '桔子存证'">
          <img src="../../../assets/icon/hechang.png" v-else-if="it == '合畅签'">
          <img src="../../../assets/icon/wuyou.jpg" v-else-if="it == '无忧'">
          <img src="../../../assets/icon/chengxinqian.png" v-else-if="it == '橙信签'">
          <img src="../../../assets/icon/anxin.jpg" v-else-if="it == '安心借条'">
          <img src="../../../assets/icon/lvxing.jpg" v-else-if="it == '履行证'">
          <img src="../../../assets/icon/huoban.jpg" v-else-if="it == '伙伴合约'">
          <img src="../../../assets/icon/yizhi.jpg" v-else-if="it == '一纸签'">
          <img src="../../../assets/icon/youcun.jpg" v-else-if="it == '存证优管家'">
          <img src="../../../assets/icon/yixin.png" v-else-if="it =='宜信电子签'">
          <img src="../../../assets/icon/jijian.png" v-else-if="it == '极简合约'">
          <img src="../../../assets/icon/suxin.jpg" v-else-if="it == '速信签'">
          <img src="../../../assets/icon/chengxinjian.jpg" v-else-if="it == '橙信见证'">
          <img src="../../../assets/icon/heyue.jpg" v-else-if="it == '合约说'">

          <div v-if="!getResult[it].hasOwnProperty('msg')" class="ptype">{{it}}不存在</div>
          <div v-else class="ptype" style="color: red">
            <div>{{it}}存在</div>
            <div>总计：{{getResult[it]['zongji']}}</div>
            <div>当前：{{getResult[it]['dangqian']}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";

export default {
  mixins: [getReports],
  components: {
    DocumentGenerator
  },

  methods: {
    ...mapActions("userReport/jt", ["getJt"]),
    load (refresh = false) {
      this.getJt(refresh).then((res) => res && this.getReports())
    },
  },
  computed: {
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/jt", ["date","result"]),
    getResult(){
      return JSON.parse(this.result)
    }

  },
  created () {
    this.load();
  },
};
</script>

<style lang="scss" scoped>

.text-row{
  // margin: 10px 50px 10px 10px;
}
.el-card__body{
  padding: 5px!important;
}
.name{
  font-weight: bold;
  color: #000;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  width: max-content;
}
.cbody{
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  height: 100%;
  overflow: hidden;
  align-items: center;
}
.ptype{
  margin-left: 10px;
}
.tag-red{
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}
.cbody img{
  width: 85px;
  height: 85px
}

.tag-green{
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}
</style>
