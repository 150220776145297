const examUser ={
  '0': [
    {name:'通过', clickName: 'handlePass'},
    {name:'拒绝', clickName: 'handleRefuse'},
    {name:'拉黑', clickName: 'addBlack'},
    {name:'备注', clickName: 'handleRemark'},
  ],
  '1': [
    {name:'拒绝', clickName: 'handleRefuse'},
    {name:'拉黑', clickName: 'addBlack'},
    {name:'备注', clickName: 'handleRemark'},
  ],
  '2': [
    {name:'通过', clickName: 'handlePass'},
    {name:'拉黑', clickName: 'addBlack'},
    {name:'备注', clickName: 'handleRemark'},
  ],
  '-1': [
    {name:'通过', clickName: 'handlePass'},
    {name:'拒绝', clickName: 'handleRefuse'},
    {name:'移出', clickName: 'delBlack'},
    {name:'备注', clickName: 'handleRemark'},
  ],
}

export  {
  examUser,
}
