<template>
  <div id="charts1" ref="charts1" ></div>
</template>

<script>
import * as echarts from 'echarts'
var color = ['#FC4567', '#2F8DF4', '#C25EC4']
export default {
  name: "bing",
  props: {
    value1: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    value1(val) {
      console.log(val)
      this.drawChart()
    },
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      this.chart = echarts.init(this.$refs.charts1)
      //https://www.makeapie.cn/echarts_content/xeDQNTsDja.html
      this.chart.setOption(
        {

          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ['近12月', '近06月', '近03月', '近01月']
          },
          series: [
            {
              type: 'bar',
              data: this.value1
            }
          ]
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
